import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '@stateManage/auth';
import {profileQuery} from '@stateManage/profile';
import {IProfile} from '@interfaces/interface.user';
import {HelpersService} from '@helpers';
import {GLOBAL_STRS} from '@globalsStr';

@Injectable({
    providedIn: 'root'
})
export class AdminGuard implements CanActivate {
    public profile: IProfile;
    public GlobalStr = GLOBAL_STRS;
    constructor(private  router: Router,
                private auth: AuthService,
                public  Tools: HelpersService,
    ) {
        this.profile = this.Tools.cloneObject(profileQuery.getValue());
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        if (this.profile['profile_has_user']['user_type']['fix_id'] == this.GlobalStr.ADMIM) {
            console.log('se queda desde el admin guard')
            return true;
        } else {
            // no va saguir y redirige a la ruta que se ponga por default
            console.log('se fue al login desde el admin guard')
            return ;
            this.router.navigate(['/login']);
            return false;
        }
    }


}
