// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
    production: false,
    //domain: 'https://console.cloud.thehigher.net',
    domain: 'https://dev.admin.thehigher.net',
    // domain: 'https://9cklkfz7-8000.use2.devtunnels.ms',
    client_backend_id: 'thnI7Oc27xwBZKJ5GiU0DRNtO6gCo2oDJYzB3CHH',
    client_backend_secret: '6zN5haE5wAdLVYYVyRTXkPHtnsv5Mq890jBGZjufyl2GQpH9lqiiRGXtW25J9q5onTHKAexnAgDQkBVyJSy8uU2TjuaWwEmiVtuImRMtHLCtjvxlLPadBF7puPqwuEbP',
    firebaseConfig: {
        apiKey: 'AIzaSyCuXUw-MSZ6qF9fL5L9KVPYVKvutr_GhQs',
        authDomain: 'heigher-28c30.firebaseapp.com',
        projectId: 'heigher-28c30',
        storageBucket: 'heigher-28c30.appspot.com',
        messagingSenderId: '168560270680',
        appId: '1:168560270680:web:d299b57c1ec8e670bb592d',
        measurementId: 'G-3KZ46P60PE'
    },
    idFace: '940051860146788',
    client_id_linkedin: '86rul7tt11u6tz',
    client_secret_linkedin: 'xk6LY3zJqGQisFLw',
    // redirect_url_linkedin: 'http://localhost:8100/callback',
    redirect_url_linkedin: 'https://dev.app.thehigher.net/callback',
    pin_winslow: '5GMDMZ'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
