import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import {AuthService} from '@stateManage/auth';
import { map, catchError, timeout } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(private  router: Router,
                private auth: AuthService,
    ) {
    }

    // canActivate(
    //     next: ActivatedRouteSnapshot,
    //     state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    //
    //    if (this.auth.isLogged()) {
    //        console.log('this.auth.isLogged() en el auth.guard', this.auth.isLogged())
    //         return true;
    //     } else {
    //        console.log('entro al else y va para el login')
    //         // no va saguir y redirige a la ruta que se ponga por default
    //         this.router.navigate(['/login']);
    //         return false;
    //     }
    // }
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this.checkCondition().pipe(
            timeout(5000), // Timeout after 5 seconds
            map((isAllowed) => {
                if (isAllowed) {
                    console.log('isAllowed')
                    return true;
                } else {
                    console.log('isNotAllowed')
                    this.router.navigate(['/not-allowed']); // Redirect if condition fails
                    return false;
                }
            }),
            catchError((error) => {
                console.error('Guard timed out or encountered an error:', error);
                this.router.navigate(['/timeout']);
                return of(false); // Redirect to timeout page
            })
        );
    }

    // Simulate an async condition
    checkCondition(): Observable<boolean> {
        // Replace with your actual condition or async call
        return of(true); // Simulates a successful check
    }


}
