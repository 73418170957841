import { Query } from '@datorama/akita';
import { ProfileStore, profileStore } from './profile.store';
import { IProfile } from '@interfaces/interface.user';


export class ProfileQuery extends Query<IProfile> {

  constructor(protected store: ProfileStore) {
    super(store);
  }

  updateProfile(val) {
    this.store.update(val);
  }

  getValue(): IProfile {
    console.log('entro al getValue');
    return this.store._value();
  }

}

export const profileQuery = new ProfileQuery(profileStore);
