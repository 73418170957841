import { Component } from '@angular/core';

import { Platform, NavController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TranslateService } from '@ngx-translate/core';
import { Toast } from '@ionic-native/toast/ngx';
import {Router} from '@angular/router';
import {take} from 'rxjs/operators';

/*--Tools--*/
import {GLOBAL_STRS} from '@globalsStr';

/*--State--*/
import {authQuery} from '@stateManage/auth';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {

  /*--Vars--*/
  lastTimeBackPress = 0;
  timePeriodToExit = 2000;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private translateService: TranslateService,
    private router: Router,
    private toast: Toast,
    private navCtrl: NavController
  ) {
    setTimeout(()=> {
      console.log('validando timeout lll')
      this.initializeApp();
      this.backButtonEvent();
    },1000 );
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      //this.splashScreen.hide();
      /*--Set defaul language--*/
      const lang  = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en';
      this.translateService.setDefaultLang(lang);
      this.translateService.use(lang);
      /*--Fin--*/
      /*--User is login auto redirect dashboard--*/
      if (GLOBAL_STRS.AUTO_REDIRECT_LOGIN) setTimeout( () => this.navigateInit(), 500);
    });
  }

  navigateInit() {
    //this.ngZone.run( () => {
      authQuery.isLoggedIn$
          .pipe(take(1))
          .subscribe(  isLoggedIn => {
            console.log('is eject redirect method');
            setTimeout( () => this.splashScreen.hide(), 2000);
            if(isLoggedIn){
              //this.router.navigateByUrl('/patient/photosBody').then();
              this.navCtrl.navigateRoot('/dashboard');
            } else {
              const init = localStorage.getItem('isInit');
              if(init) {
                this.navCtrl.navigateRoot('/');
              } else {
                this.navCtrl.navigateRoot('/intro');
              }
            }
          });
    //});
  }

  backButtonEvent() {
    this.platform.backButton.subscribe(async () => {
      console.log('page actual-> ', this.router.url);
      // Urls exit app
      const urlsExit = ['/', '/login', '/home'];
      if (urlsExit.includes(this.router.url)) {
        if (new Date().getTime() - this.lastTimeBackPress < this.timePeriodToExit) {
          //this.platform.exitApp(); // Exit from app
          navigator['app'].exitApp(); // work for ionic 4

        } else {
          let press_to_exit = '';
          this.translateService.get('PRESS_AGAIN_TO_EXIT_APP').subscribe(value => press_to_exit = value);
          this.toast.showWithOptions({
            message: press_to_exit + '.',
            duration: 2000, // ms
            position: 'bottom',
            addPixelsY: -180,
          })
              .subscribe(toast => {
                // console.log(JSON.stringify(toast));
              });
          this.lastTimeBackPress = new Date().getTime();
        }
      }
    });
  };
}
